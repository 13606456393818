import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_advertisement_slot_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.divider,
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        label: 'Label',
      },
      {
        name: 'theme_component_advertisement_slot_label_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.secondary.main,
      },
      {
        name: 'theme_component_advertisement_slot_label_color',
        label: 'Text color',
        type: 'color',
        default: theme.palette.secondary.contrastText,
      },
    ],
    configuration: [],
  });
}