import type { CategoriesViewProps } from '../../types';
import * as React from 'react';
import { CollectionsA as CollectionsComponent } from '../../containers/CollectionsA';

export function View(props: CategoriesViewProps) {
  return (
    <>
      <CollectionsComponent {...props} />
    </>
  );
}
